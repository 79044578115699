/* .entity-table-wrapper {

} */
.entity-table {
    text-align: center;
    margin-top: 0px;
    border-collapse: separate;
    border-spacing: 0;
}
.entity-table .add-entity {
    left: 0;
    position: sticky;
    display: block;
}
.entity-table .end-row {
    text-align: left;
}
/* .entity-table tbody tr:active,
.entity-table tbody tr:focus,
.entity-table tbody tr:hover,
.entity-table tbody tr:hover td {
    filter: brightness(125%);
} */
.entity-table thead {
    position: sticky;
    z-index: 3000;
    top: 0px;
}
.entity-table thead td {
    background-color: var(--freeze-accent);
}
td.freeze-col {
    left: 0;
    position: sticky;
    /* background-color: inherit; */
    z-index: 1500;
    /* border: thin solid; */
}
thead tr td.freeze-col {
    z-index: 2000;
}
tbody tr:nth-child(even) td.freeze-col {
    background-color: var(--freeze-accent);
}
tbody tr:nth-child(odd) td.freeze-col {
    background-color: var(--freeze-dark);
}

.entity-table thead::after {
    content: '';
    position: absolute;
    height: 1em;
    width: 100%;
    bottom: -1em;
    left: 0;
    background: linear-gradient(rgba(0,0,0,.25) 0%, rgba(0,0,0,0) 100%);
    pointer-events: none;
    /* background: magenta; */
}
.entity-table td.freeze-col::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1em;
    top: 0;
    right: -1em;
    background: linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,.25) 100%);
    pointer-events: none;
}

.entity-table td {
    border-right: thin solid var(--border-color);
    border-bottom: thin solid var(--border-color);
}
.entity-table td:first-child {
    border-left: thin solid var(--border-color);
}
.entity-table {
    border-top: thin solid var(--border-color);
}
.entity-list {
    margin-bottom: 4em;
}
.entity-list > li {
    margin-bottom: 1em;
    margin-top: 1em;
}
.entity-list li ul {
    margin-left: 1em;
}
.fullnext {
    padding: 0;
    position: relative;
    /* display: flex; */
}
.fullnext a {
    /* padding: 1em; */
    /* border: thin dotted red; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    align-content: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    transition: all .2s ease;
}
.fullnext a:hover {
    font-size: 150%;
    background-color: var(--theme);
}