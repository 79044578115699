.printSettings {
    margin-bottom: 1em;
    max-width: 8.5in;
}
.printTypeSelector,
.printTypeSelector td {
    border-collapse: collapse;
    border: thin solid;
    padding: 0 0 0 0;
}
.printTypeSelector label {
    margin: 0;
    padding: 0 0 0 0;
    height: 100%;
    width: 100%;
}
.printTypeSelector label input[type='radio'] {
    background-color: var(--theme);
    display: block;
    -webkit-appearance: none;
    appearance: none;
    margin: auto;
    padding: 0;
    height: 100%;
    width: 100%;
    min-height: 1em;
    text-align: center;
}
.printTypeSelector label input[type='radio']:before {
    content: '☐';
    text-align: center;
}
.printTypeSelector label input:checked {
    background-color: var(--active);
}
.printTypeSelector label input[type='radio']:checked:before {
    content: '🗹'
}
.printTypeSelector td {
    padding: 0;
}
@media print {
    .SocketDocEdit h1:first-of-type
    {
        display: none;
    }
    body,
    html,
    #content-output,
    .container .SocketDocEdit,
    .docEditor,
    .container,
    .container.DocPage,
    .docTab,
    #root > div,
    .SocketDocEdit
    {
        display: block;
        clear: both;
        width: 100%;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        overflow: visible visible;
        margin: 0;
        padding: 0 0 0 0;
    }
    #content-output {
        max-width: auto;
    }
    html,
    body,
    .container,
    .footer,
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}
#content-output {
    max-width: 8.5in;
}
#content-output.Entity.Cards {
    display: block;
    columns: 3;
    column-gap: 0;
}
.card {
    font-size: 8pt;
    display: block;
    break-inside: avoid;
    page-break-inside: avoid;
    border: 2pt solid;
    padding: .5em;
    margin: 2pt 1pt;
}
.card:first-of-type {
    margin-top: 0;
}
.card hr {
    border: none;
    border-top: thin dotted;
    margin: .5em 0;
}
#content-output h1,
#content-output h2,
#content-output h3,
#content-output h4,
#content-output h5,
#content-output h6 {
    font-weight: bolder;
}
#content-output li {
    list-style-type: disc;
    /* margin-left: 1em; */
    list-style-position: inside;
}
#content-output ul ul {
    padding-left: 1em;
}
#content-output code {
    word-wrap: break-word;
    /* border: thin solid; */
    font-style: italic;
}
pre#content-output {
    word-wrap: break-word;
    white-space: pre-wrap;
}
#content-output h1,
#content-output h2,
#content-output h3,
#content-output h4,
#content-output h5,
#content-output h6 {
    break-after: avoid;
    page-break-after: avoid;
}
#content-output.Stat.Blocks {
    columns: 2;
    column-gap: 2em;
    font-size: 10pt;
    column-rule: 2pt solid;
}
#content-output.Stat.Blocks h2 {
    border-top: 2pt solid;
    margin-top: 1em;
}
#content-output.Item.Table {
    font-size: 8pt;
    -webkit-print-color-adjust: exact;
}
#content-output.Stat.Blocks.JSON,
#content-output.Entity.Cards.JSON {
    columns: 1;
}

/* #content-output .card *[class] {
    margin: 2px;
    background-color: rgba(255, 255, 255, .1);
} */

#content-output .tag {
    background-color: transparent;
    color: inherit;
    text-shadow: none;
} 