@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Rose:wght@300;400;700&display=swap');

:root {
    --root-fs: 18px;
    --color-mode: "light";
    --light: #fff;
    --dark:  #1C172B;
    --haze: #f2f5f7;
    --bubble: rgb(36,36,38);
    --accent: var(--haze);
    --bg: var(--light);
    --code-bg: var(--accent);
    --overlay: var(--light);
    --text: #111;
    --font: 'Open Sans', sans-serif;
    --border-color: #eee;
    --inline-color: #687bff;
    --theme: #B559CD;
    --ease: ease;
    --heading: var(--dark);
    --glow: none;
    --active: #6b42f5;
    /* dark mode */
    --color-mode: "dark";
    --theme: #B559CD;
    --bg: var(--dark);
    --text: #d1c4d4;
    --accent: var(--bubble);
    --overlay: var(--bubble);
    --freeze-accent: rgb(47, 47, 48);
    --freeze-dark: #231d36;
    --border-color: black;
    --darker: #161222;
    --heading: white;
    --glow: 0 0 10px #B559CD;
}

* {
    box-sizing: border-box;
    /* -webkit-appearance: none; */
    margin: 0;
    padding: 0;

}

body, html {
    scroll-behavior: smooth;
    font-kerning: normal;
    -webkit-text-size-adjust: 100%;
    font-size: var(--root-fs);
}
body {
    font-family: 'Open Sans', sans-serif;
    background-color: var(--darker);
    color: var(--text);
    line-height: 1.5;
    /* max-width: 1440px; */
    margin: 0 auto;
    position: relative;
    font-kerning: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.container {
    background-color: var(--bg);
    /* padding-left: .5em; */
}
.navpane {
    background-color: var(--bg);
}

h1,h2,h3,h4,h5 {
    font-family: "Red Rose";
    font-weight: 300;
    padding: 0.33rem 0;
    color: var(--heading);
    line-height: 1.35;
    text-shadow: var(--glow);
}
h1 input {
    font-family: "Red Rose";
}
h1 {
  font-size: 200%;
}
h2 {
  font-size: 175%;
}
h3 {
  font-size: 150%;
}
h4 {
  font-size: 125%;
}
h5 {
  font-size: 120%;
}
h6 {
  font-size: 100%;
}

a {
    text-decoration: none;
    color: var(--theme);
}

table {
    margin: 1.5rem 0;
    width:  100%;
    border: none;
}
tbody tr:nth-child(even),
tbody tr:nth-child(even) td {
    background-color: var(--accent);
    /* box-shadow: 0 1rem 0.75rem -0.75rem rgba(0,0,0,0.07); */
}
tbody tr:nth-child(odd),
tbody tr:nth-child(odd) td {
    background-color: var(--bg);
    /* box-shadow: 0 1rem 0.75rem -0.75rem rgba(0,0,0,0.07); */
}
thead tr td,
thead tr th {
    background: var(--bubble);
    color: var(--light);
    font-weight: 400;
    text-align: left;
}
/* :not(thead) tr:hover td {
    box-shadow: 0 0 3px var(--active) inset;
} */
tbody tr:nth-child(odd):hover td {
    background-color: var(--freeze-dark);
}
tbody tr:nth-child(even):hover td {
    background-color: var(--freeze-accent);
}
tbody tr:nth-child(odd):hover td.freeze-col {
    filter: brightness(1.5);
}
tbody tr:nth-child(even):hover td.freeze-col {
    filter: brightness(1.5);
}
th, td {
    padding: 0 0.5rem;
    font-weight: 400;
    /* &:not(:first-child)
      padding-left: 1.5rem */
}
td > span {
    padding: 0 3pt;
}
tr.meta {
    text-align: center;
}
ul {
    list-style-type: none;
}

.button,
button,
input[type="submit"] {
    background-color: var(--theme);
    color: var(--light);
    border-radius: 0.25rem;
    /* display: inline-block; */
    /* padding: 0.75rem 1.25rem; */
    padding: .2rem;
    text-align: center;
    border: transparent;
}
.button:disabled,
button:disabled,
input[type="submit"]:disabled {
    opacity: .5;
}
.button:not(:disabled):hover,
button:not(:disabled):hover,
input[type="submit"]:not(:disabled):hover {
    filter: brightness(120%);
}
.button:not(:disabled):active,
button:not(:disabled):active,
input[type="submit"]:active {
    background-color: var(--active);
}
body {
    scrollbar-color: var(--theme) transparent;
}
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: transparent;
    opacity: 0.5;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
    background-color: transparent;
    background-color: var(--darker);
    /* box-shadow: 0 0 6px inset rgba(0, 0, 0, .3); */
}
::-webkit-scrollbar-thumb {
    background-color: var(--theme);
    border-radius: 1em;
    opacity: 0.5;
}
:focus-visible {
    outline: var(--theme) solid 2px;
}
input, select, button, textarea {
    font-size: var(--root-fs);
}
select,
input:not([type]),
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
    background-color: transparent;
    color: var(--theme);
    border: transparent;
}
select {
    background-color: transparent;
    color: var(--theme);
    border: transparent;
}
select option {
    background-color: var(--bg);
}
select option:hover {
    background-color: var(--active);
}
select:focus-visible {
    outline-offset: 0px;
}
::selection {
    background-color: var(--active);
    color: white;
}
label {
    border-radius: .3em;
    background-color: var(--theme);
    padding: .1em .2em;
    margin: .5em;
}
label:active {
    background-color: var(--active);
}
label input:not([type]),
label input[type='text'],
label input[type='password'] {
    color: var(--bg);
}
label * {
    color: var(--bg);
}
label select,
label select option {
    color: var(--bg);
    background: var(--theme);
}
label select optgroup {
    color: var(--theme);
    background: var(--bg);
}
.pill {
    background-color: var(--text);
    color: var(--text);
}
hr {
    margin: 1em 0;
}
label.transparent {
    background-color: transparent;
    color: var(--theme);
}
label input[type='number'],
label input[type='text'],
label input[type='password']
{
    color: var(--bg);
}

hr {
    border: none;
    border-bottom: thin solid var(--active);
}


@media print {
    .navpane,
    div.docEditor .docNav,
    .docSave,
    .printSettings,
    .printless
    {
        display: none;
    }
}