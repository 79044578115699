.muted {
    opacity: .3;
}
ul.equipment ul {
    margin-left: 1em;
    list-style-type: none;
}
.equipment li span::after {
    content: ', ';
}
.equipment li span:last-child::after {
    content: '';
}
.EntityEditTab .panel textarea {
    width: 100%;
}
@media screen and (min-width: 880px) {
    .EntityEditTab {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        justify-items: stretch;
        justify-content: stretch;
    }
    .EntityEditTab .panel {
        overflow: auto;
        margin: 1px;
        padding: .5em;
        background-color: var(--freeze-dark);
    }
    .EntityEditTab .panel table {
        width: 100%;
    }
    .namepanel {
        grid-row: span 1;
    }
    .aspectspanel {
        grid-column: span 2;
    }
    .primariespanel {
        grid-column: span 2;
    }
    .tacticalspanel {
        grid-column: span 2;
        grid-row: span 2;
    }
    .thresholdspanel {
        grid-column: span 2;
    }
    .poolspanel {
        grid-column: span 2;
    }
    .skillspanel {
        grid-column: span 3;
        grid-row: span 3;
    }
    .equipmentpanel {
        grid-column: span 2;
    }
    .infopanel {
        grid-column: span 2;
    }
}
.keycell > div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.keycell > div > * {
    flex: 0 0 auto;
}
.keycell button.icon {
    background-color: transparent;
    /* background-color: red; */
    padding: 0 0 0 0;
    border-radius: unset;
}
.keycell button.icon .icon {
    display: block;
}
.keycell > div > :nth-child(1),
.keycell > div > :nth-child(2) {
    /* background-color: red; */
    flex-basis: 2em;
}
.keycell > div > :nth-child(3) {
    flex: 1 1 auto;
}
.keycell > div > :nth-child(4) {
    flex: 1 1 6ch;
    text-align: right;
    position: relative;

}
.keycell > div > :nth-child(4):after {
    content: 'xp';
    font-size: .7em;
    vertical-align: top;
    opacity: .5;
}

.keycell a:hover,
.keycell button:hover {
    text-shadow: 0 0 1em var(--theme);
}
.pool-boxes {
    column-span: all;
}