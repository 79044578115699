.loginbox {
    width: 15em;
}
.loginbox > label {
    display: block;
}
.loginbox > label > span {
    display: inline-block;
    width: 5em;
}
.loginbox > label input {
    display: inline-block;
    width: 10em;
}
.loginbox input[type="submit"] {
    width: 15em;
}