div.remove-heading h2 {
    display: none;
}
div.list-unit {
    background-color: var(--freeze-dark);
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    break-inside: avoid;
}

@media print {
    .ListPage.container {
        columns: 2;
    }
    .container h1 {
        column-span: all;
    }
    div.list-unit {
        font-size: .7em;
        border: thin solid black;
        padding: 1em;
        margin-top: 0;
        margin-bottom: 1em;
    }
    .footer {
        column-span: all;
        margin-top: 0;
        margin-bottom: 0;
    }
}