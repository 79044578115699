.modal .pane label {
    display: block;
    width: fit-content;
}
.modal .pane button.inlineList {
    display: block;
    width: fit-content;
}
.modal .pane label:has(input:checked) {
    background-color: var(--active);
}
.modal .pane label:has(#item-selector-filter-name-box:not([value=''])) {
    background-color: var(--active);
}
.modal .pane .inline label {
    display: inline-block;
}