.api ul {
    margin-left: 1em;
    list-style-type: disc;
}
.api p {
    margin-bottom: 1em;
    margin-top: 1em;
}
.api blockquote {
    margin-left: 1em;
    font-style: italic;
}
.api code.block {
    display: block;
    margin: 1em;
    padding: 1em;
    border: thin solid;
}