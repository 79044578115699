.tagList{
    display: block;
    position: relative;
    /* position: absolute; */
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 100%;
    height: 100%;
    /* word-break: break-all; */
    background-color: transparent;
    color: var(--theme);
    overflow: hidden;
}
.tagList:hover {
    background-color: var(--theme);
    color: var(--text);
}
.listCell {
    padding: 0 0 0 0;
    position: relative;
}
.tagList span {
    display: inline-block;
}
/* td label {
    display: block;
    width: 100%;
} */
label.checked {
    background-color: var(--active);
}
.scroll table {
    margin-top: 0;
}
.inlineList {
    margin: .5em;
}