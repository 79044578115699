@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Red+Rose:wght@300;400;700&display=swap";
.App {
  text-align: center;
}

.App-header {
  color: #fff;
  background-color: #282c34;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.tag:before {
  content: "#";
}

.tag, .UserInline {
  color: #000;
  background-color: #e4e4e4;
  border-radius: .3em;
  margin: 1px;
  padding: 0 3px;
  box-shadow: 0 2px 3px #00000049;
}

.tag, .UserInline a {
  text-decoration: none;
}

tr.unlisted, tr.trashed, li.unlisted, li.trashed {
  opacity: .5;
}

tr.unlisted:hover, tr.trashed:hover {
  opacity: 1;
}

.devmenu {
  background: #5a000049;
  border: thin solid #ff4500;
  padding: 1em;
}

.devmenu ul {
  display: none;
}

.devmenu:hover ul {
  display: block;
}

#app > div {
  flex-flow: row;
  align-items: stretch;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.HeadBranding img {
  max-width: 100%;
}

.navpane {
  flex-grow: 1;
  flex-basis: 200px;
  order: -1;
  min-width: 200px;
  max-width: 200px;
  padding: 1em;
  overflow-y: auto;
}

.navpane ul {
  list-style-type: none;
}

.navpane ul li ul {
  margin-left: 1em;
}

.container {
  flex-grow: 4;
  order: 2;
}

.container:not(.DocPage) {
  overflow-y: scroll;
}

.container.DocPage {
  width: calc(100vw - 200px);
  max-width: calc(100vw - 200px);
}

.container .SocketDocEdit {
  width: 100%;
  height: 100%;
}

.modal .pane {
  z-index: 12000;
  background-color: #fff;
  flex-direction: column;
  width: 50vw;
  height: 60vh;
  padding: 1em;
  display: flex;
  position: fixed;
  top: 20vh;
  left: 25vw;
  overflow: hidden;
  box-shadow: 0 0 1em #000;
}

.modal .pane ul, .modal .pane ol {
  overflow-y: auto;
}

.modal {
  content: " ";
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.modal .pane {
  background-color: var(--bg);
}

.modal .pane .spacer {
  flex-grow: 10;
}

.modal .bg-button {
  content: " ";
  z-index: 2000;
  background-color: #000000a2;
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.modal .scroll {
  padding-bottom: 1em;
  overflow: scroll;
}

button.delete, .button.delete {
  background-color: red;
}

.button {
  text-align: center;
  border: thin solid;
  border-radius: .5em;
  padding: .1em .3em;
  display: inline-block;
}

.button:hover {
  background: #00000025;
  box-shadow: inset 0 0 3px;
}

table thead {
  font-weight: bolder;
}

.input-cell {
  padding: 0;
  position: relative;
}

.input-cell input, .input-cell select, .input-cell textarea {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

select, input:not([type]), input[type="text"], input[type="password"] {
  padding: .1em .3em;
}

label {
  -webkit-user-select: none;
  user-select: none;
}

form > * {
  display: block;
}

form input[type="submit"] {
  width: 100%;
  display: block;
}

form label {
  flex-direction: row;
  display: flex;
}

form label input {
  flex-grow: 1;
}

.pill {
  border-radius: 1em;
  margin: .1em;
  padding: 0 1em 0 .3em;
  font-size: 12pt;
  display: inline-block;
  position: relative;
  box-shadow: 0 .2em .2em #00000057;
}

.listHead {
  min-width: 10ch;
}

table thead tr td, table thead tr th {
  text-align: center;
}

::placeholder {
  color: var(--text);
  opacity: .5;
}

[title]:hover {
  cursor: help;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media screen and (max-width: 641px) {
  body {
    width: 100vw;
  }

  #root > div {
    flex-direction: column;
    height: auto;
  }

  div.container {
    margin-bottom: 5em;
  }

  div.container, div.container.DocPage {
    width: 95vw;
    max-width: 95vw;
    margin-left: auto;
    margin-right: auto;
  }

  div.docEditor {
    width: 100%;
    height: auto;
    max-height: none;
    overflow: auto;
  }

  div.docTab {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: none;
    overflow: hidden auto;
  }

  div.panel {
    width: 100%;
    overflow: auto;
  }

  div.EntityEditTab {
    display: block;
  }

  div.container div.SocketDocEdit {
    width: auto;
    height: auto;
  }

  div.navpane {
    overflow-y: initial;
    width: 100%;
    max-width: none;
    height: auto;
    max-height: none;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .navpane {
    flex-basis: 0;
  }

  #nav {
    display: none;
  }

  #nav.show {
    display: block;
  }

  body div.docSave {
    width: 100%;
    height: 1.7em;
    inset: auto 0 0;
  }

  body div.docSave button {
    width: 100%;
  }

  body .modal .pane {
    width: 90vw;
    height: 90vh;
    top: 5vh;
    left: 5vw;
  }

  body .modal .pane label input {
    width: 15ch;
  }
}

@media screen {
  .lightbg {
    display: none;
  }
}

@media print {
  .darkbg, .namepanel button, .aspectpanel button, .aspectpanel td:nth-child(3), .statspanel .muted, .poolspanel button, .equipmentpanel .modify, html body .EntityEditTab .panel.noprint, .equipmentpanel button {
    display: none;
  }

  .portraitpanel button {
    visibility: hidden;
  }

  .portraitpanel img {
    visibility: visible;
  }

  html body .panel table {
    width: 100%;
  }

  html body label, html body button {
    color: #000;
    background-color: #0000;
  }

  html body div.panel.equipmentpanel {
    flex-grow: 2;
  }

  html {
    height: auto;
    font-size: 10pt;
    overflow: visible;
  }

  html body {
    color: #000;
    text-shadow: none;
    background-color: #0000;
    height: auto;
    overflow: auto;
  }

  body h1, body h2, body h3, body h4, body h5, body h6 {
    color: #000;
    text-shadow: none;
  }

  body tbody tr:nth-child(2n), body tbody tr:nth-child(2n) td {
    background-color: #00000025;
  }

  body tbody tr:nth-child(odd), body tbody tr:nth-child(odd) td {
    background-color: #0000;
  }

  body thead tr td, body thead tr th {
    color: #000;
    text-align: left;
    background-color: #00000025;
    font-weight: 400;
  }

  body tbody tr:nth-child(2n) td.freeze-col {
    background-color: #00000049;
  }

  body tbody tr:nth-child(odd) td.freeze-col {
    background-color: #0000;
  }

  body .entity-table thead td {
    background-color: #00000049;
  }

  body .EntityEditTab .panel {
    background-color: #0000;
    border: 1px solid #000;
    overflow: visible;
  }

  html[lang] {
    --root-fs: 10px;
  }

  html[lang] #app > div {
    width: unset;
    height: unset;
    overflow: unset;
    display: block;
  }
}

:root {
  --root-fs: 18px;
  --color-mode: "light";
  --light: #fff;
  --dark: #1c172b;
  --haze: #f2f5f7;
  --bubble: #242426;
  --accent: var(--haze);
  --bg: var(--light);
  --code-bg: var(--accent);
  --overlay: var(--light);
  --text: #111;
  --font: "Open Sans", sans-serif;
  --border-color: #eee;
  --inline-color: #687bff;
  --theme: #b559cd;
  --ease: ease;
  --heading: var(--dark);
  --glow: none;
  --active: #6b42f5;
  --color-mode: "dark";
  --theme: #b559cd;
  --bg: var(--dark);
  --text: #d1c4d4;
  --accent: var(--bubble);
  --overlay: var(--bubble);
  --freeze-accent: #2f2f30;
  --freeze-dark: #231d36;
  --border-color: black;
  --darker: #161222;
  --heading: white;
  --glow: 0 0 10px #b559cd;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  scroll-behavior: smooth;
  font-kerning: normal;
  -webkit-text-size-adjust: 100%;
  font-size: var(--root-fs);
}

body {
  background-color: var(--darker);
  color: var(--text);
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  margin: 0 auto;
  font-family: Open Sans, sans-serif;
  line-height: 1.5;
  display: flex;
  position: relative;
}

.container, .navpane {
  background-color: var(--bg);
}

h1, h2, h3, h4, h5 {
  color: var(--heading);
  text-shadow: var(--glow);
  padding: .33rem 0;
  font-family: Red Rose;
  font-weight: 300;
  line-height: 1.35;
}

h1 input {
  font-family: Red Rose;
}

h1 {
  font-size: 200%;
}

h2 {
  font-size: 175%;
}

h3 {
  font-size: 150%;
}

h4 {
  font-size: 125%;
}

h5 {
  font-size: 120%;
}

h6 {
  font-size: 100%;
}

a {
  color: var(--theme);
  text-decoration: none;
}

table {
  border: none;
  width: 100%;
  margin: 1.5rem 0;
}

tbody tr:nth-child(2n), tbody tr:nth-child(2n) td {
  background-color: var(--accent);
}

tbody tr:nth-child(odd), tbody tr:nth-child(odd) td {
  background-color: var(--bg);
}

thead tr td, thead tr th {
  background: var(--bubble);
  color: var(--light);
  text-align: left;
  font-weight: 400;
}

tbody tr:nth-child(odd):hover td {
  background-color: var(--freeze-dark);
}

tbody tr:nth-child(2n):hover td {
  background-color: var(--freeze-accent);
}

tbody tr:nth-child(odd):hover td.freeze-col, tbody tr:nth-child(2n):hover td.freeze-col {
  filter: brightness(1.5);
}

th, td {
  padding: 0 .5rem;
  font-weight: 400;
}

td > span {
  padding: 0 3pt;
}

tr.meta {
  text-align: center;
}

ul {
  list-style-type: none;
}

.button, button, input[type="submit"] {
  background-color: var(--theme);
  color: var(--light);
  text-align: center;
  border: #0000;
  border-radius: .25rem;
  padding: .2rem;
}

.button:disabled, button:disabled, input[type="submit"]:disabled {
  opacity: .5;
}

.button:not(:disabled):hover, button:not(:disabled):hover, input[type="submit"]:not(:disabled):hover {
  filter: brightness(120%);
}

.button:not(:disabled):active, button:not(:disabled):active, input[type="submit"]:active {
  background-color: var(--active);
}

body {
  scrollbar-color: var(--theme) transparent;
}

::-webkit-scrollbar {
  opacity: .5;
  background-color: #0000;
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: #0000;
  background-color: var(--darker);
}

::-webkit-scrollbar-corner {
  background-color: #0000;
  background-color: var(--darker);
}

::-webkit-scrollbar-thumb {
  background-color: var(--theme);
  opacity: .5;
  border-radius: 1em;
}

:focus-visible {
  outline: var(--theme) solid 2px;
}

input, select, button, textarea {
  font-size: var(--root-fs);
}

select, input:not([type]), input[type="text"], input[type="password"], input[type="number"], textarea, select {
  color: var(--theme);
  background-color: #0000;
  border: #0000;
}

select option {
  background-color: var(--bg);
}

select option:hover {
  background-color: var(--active);
}

select:focus-visible {
  outline-offset: 0px;
}

::selection {
  background-color: var(--active);
  color: #fff;
}

label {
  background-color: var(--theme);
  border-radius: .3em;
  margin: .5em;
  padding: .1em .2em;
}

label:active {
  background-color: var(--active);
}

label input:not([type]), label input[type="text"], label input[type="password"], label * {
  color: var(--bg);
}

label select, label select option {
  color: var(--bg);
  background: var(--theme);
}

label select optgroup {
  color: var(--theme);
  background: var(--bg);
}

.pill {
  background-color: var(--text);
  color: var(--text);
}

hr {
  margin: 1em 0;
}

label.transparent {
  color: var(--theme);
  background-color: #0000;
}

label input[type="number"], label input[type="text"], label input[type="password"] {
  color: var(--bg);
}

hr {
  border: none;
  border-bottom: thin solid var(--active);
}

@media print {
  .navpane, div.docEditor .docNav, .docSave, .printSettings, .printless {
    display: none;
  }
}

.spinner {
  width: 50px;
  height: 50px;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    opacity: 1;
    transform: rotate(0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}

.spinbox.fullpage {
  width: 100%;
  padding-top: 25%;
  padding-left: 50%;
}

.spinner.small {
  width: 1em;
  height: 1em;
}

.footer {
  background-color: var(--darker);
  margin-top: 2em;
  padding: .5em .5em 2em;
  position: sticky;
  top: 100vh;
  bottom: 0;
}

.foot-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.small {
  font-size: .8em;
}

.subtle {
  opacity: .75;
}

.foot-logo {
  width: 50px;
  height: 50px;
}

@keyframes spin {
  0% {
    opacity: 1;
    transform: rotate(0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}

@keyframes unspin {
  100% {
    transform: rotate(720deg);
  }
}

.patronList > div {
  border-bottom: thin solid;
  margin-bottom: 1em;
  padding-bottom: 1em;
}

.cyclone li {
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: bolder;
}

.cyclone ul {
  columns: 2;
}

.helix li {
  font-size: 1.2em;
}

.helix ul {
  columns: 2;
}

.spiral li {
  font-size: 1em;
}

.spiral ul {
  columns: 3;
}

.loginbox {
  width: 15em;
}

.loginbox > label {
  display: block;
}

.loginbox > label > span {
  width: 5em;
  display: inline-block;
}

.loginbox > label input {
  width: 10em;
  display: inline-block;
}

.loginbox input[type="submit"] {
  width: 15em;
}

h1 input {
  font-size: 2rem;
  font-weight: bold;
}

.encounter {
  background-color: var(--freeze-dark);
  margin: 2pt;
  padding: 1em;
}

.encounter td > input {
  width: 100%;
}

.encounter table {
  width: auto;
}

.encounter span.hidden {
  float: right;
}

.encounter .graph {
  background-color: var(--dark);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.encounter .graph .line {
  text-align: right;
  border-right: 3px solid #fff;
  transition: all .3s;
  position: absolute;
  top: 0;
  bottom: 0;
}

.encounter .graph div {
  color: #fff;
  box-sizing: border-box;
  padding: .5em;
  transition: all .3s;
}

.encounter .graph .good {
  background-color: #48a148;
}

.encounter .graph .evil {
  background-color: #af4848;
  margin-top: 3pt;
}

.encounter .combatant {
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 1em 0;
}

.encounter .statblocks {
  background-color: var(--freeze-dark);
}

.encounter .statblocks > div {
  font-size: 85%;
}

.encounter .floatblock {
  clear: both;
}

.encounter .notes {
  width: 100%;
}

.encounter .statblocks ul {
  padding-left: 1em;
  list-style-type: disc;
  list-style-position: outside;
}

.mobile.encounter li > label {
  display: block;
}

.mobile.encounter label input {
  color: #000;
}

.mobile.encounter ul li label input[type="number"] {
  width: 50%;
}

.mobile.encounter .enli {
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 1em 0;
}

@media screen and (min-width: 500px) {
  .encounter .statblocks {
    float: right;
    max-width: 50%;
  }

  .encounter .statblocks > div {
    padding-left: 1em;
  }
}

@media screen and (max-width: 500px) {
  .encounter h3 span {
    display: block;
  }

  .encounter h3 span.hidden {
    float: initial;
  }

  .encounter .graph .line {
    color: #0000;
  }

  .encounter .stresstrack span {
    width: 100%;
    display: block;
  }

  h1 input {
    max-width: 100%;
    display: block;
  }
}

div.remove-heading h2 {
  display: none;
}

div.list-unit {
  background-color: var(--freeze-dark);
  break-inside: avoid;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
}

@media print {
  .ListPage.container {
    columns: 2;
  }

  .container h1 {
    column-span: all;
  }

  div.list-unit {
    border: thin solid #000;
    margin-top: 0;
    margin-bottom: 1em;
    padding: 1em;
    font-size: .7em;
  }

  .footer {
    column-span: all;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.docEditor .docTab .tab {
  display: none;
}

.docEditor .docTab .tab.active {
  display: block;
}

.docEditor .docNav {
  height: 2rem;
  display: flex;
}

.docEditor .docNav .button {
  flex-grow: 1;
}

.docEditor .docNav .button.active {
  background-color: var(--active);
  color: #fff;
}

.docEditor {
  height: 100%;
  max-height: 100%;
}

.docTab {
  height: calc(100% - 5.4rem);
  max-height: 100%;
  overflow: scroll;
}

.formulaEditBox {
  padding: .2em;
}

.opArray > .expressionNode:not(:last-child):after {
  content: ", ";
}

.expressionNode {
  color: var(--bg);
  background-color: #00000010;
  margin: .2em;
  padding: 0 .2em;
  display: inline-block;
  position: relative;
  box-shadow: .2em .2em .2em #00000031;
}

.expressionNode[data-op="none"] select {
  color: #8a0000;
}

.expressionNode[data-op="none"] select option, .expressionNode[data-op="none"] select optgroup {
  color: inherit;
}

.expressionNode select optgroup {
  color: var(--bg);
  background-color: var(--text);
}

.expressionNode input[type="number"] {
  width: 2em;
}

.expressionNode input, .expressionNode select {
  background-color: inherit;
  color: var(--bg);
  border: none;
}

.expressionNode select option {
  color: var(--text);
}

.expressionNode select option[data-op] {
  color: var(--bg);
}

.expressionNode select {
  padding: .2em;
}

.expressionNode select:hover {
  cursor: text;
}

[data-op="wrap"] {
  background-color: #4095bf;
}

[data-op="unwrap"] {
  background-color: #bf6a40;
}

[data-op="push"] {
  background-color: #40bf40;
}

[data-op="remove"] {
  background-color: #bf40bf;
}

[data-op="none"] {
  background-color: #f2a6a6;
}

[data-op="average"] {
  background-color: #f2c4a6;
}

[data-op="max"] {
  background-color: #f2e3a6;
}

[data-op="min"] {
  background-color: #e3f2a6;
}

[data-op="sum"] {
  background-color: #c4f2a6;
}

[data-op="add"] {
  background-color: #a6f2a6;
}

[data-op="subtract"] {
  background-color: #a6f2c4;
}

[data-op="multiply"] {
  background-color: #a6f2e3;
}

[data-op="divide"] {
  background-color: #a6e3f2;
}

[data-op="integer"] {
  background-color: #a6c4f2;
}

[data-op="fraction"] {
  background-color: #a6a6f2;
}

[data-op="decimal"] {
  background-color: #c4a6f2;
}

[data-op="best"] {
  background-color: #e3a6f2;
}

[data-op="statref"] {
  background-color: #f2a6e3;
}

[data-op="flatten"] {
  background-color: #f2a6c4;
}

.entity-table {
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 0;
}

.entity-table .add-entity {
  display: block;
  position: sticky;
  left: 0;
}

.entity-table .end-row {
  text-align: left;
}

.entity-table thead {
  z-index: 3000;
  position: sticky;
  top: 0;
}

.entity-table thead td {
  background-color: var(--freeze-accent);
}

td.freeze-col {
  z-index: 1500;
  position: sticky;
  left: 0;
}

thead tr td.freeze-col {
  z-index: 2000;
}

tbody tr:nth-child(2n) td.freeze-col {
  background-color: var(--freeze-accent);
}

tbody tr:nth-child(odd) td.freeze-col {
  background-color: var(--freeze-dark);
}

.entity-table thead:after {
  content: "";
  pointer-events: none;
  background: linear-gradient(#00000040 0%, #0000 100%);
  width: 100%;
  height: 1em;
  position: absolute;
  bottom: -1em;
  left: 0;
}

.entity-table td.freeze-col:after {
  content: "";
  pointer-events: none;
  background: linear-gradient(to left, #0000 0%, #00000040 100%);
  width: 1em;
  height: 100%;
  position: absolute;
  top: 0;
  right: -1em;
}

.entity-table td {
  border-right: thin solid var(--border-color);
  border-bottom: thin solid var(--border-color);
}

.entity-table td:first-child {
  border-left: thin solid var(--border-color);
}

.entity-table {
  border-top: thin solid var(--border-color);
}

.entity-list {
  margin-bottom: 4em;
}

.entity-list > li {
  margin-top: 1em;
  margin-bottom: 1em;
}

.entity-list li ul {
  margin-left: 1em;
}

.fullnext {
  padding: 0;
  position: relative;
}

.fullnext a {
  text-align: center;
  flex-direction: column;
  place-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  transition: all .2s;
  display: flex;
  position: absolute;
  inset: 0;
}

.fullnext a:hover {
  background-color: var(--theme);
  font-size: 150%;
}

.edit-stat-box {
  position: relative;
}

.edit-stat-box:hover input, .edit-stat-box:focus-within input {
  filter: brightness(125%);
}

.edit-stat-box input, .edit-stat-box select {
  box-sizing: border-box;
  text-align: center;
  background-color: #0000;
  border: none;
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.edit-stat-box button.whole {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.edit-stat-box:hover > button.whole {
  display: block;
}

.edit-stat-box > button.bottom {
  z-index: 500;
  width: 100%;
  height: auto;
  margin-top: 5px;
  position: absolute;
  top: calc(100% - 5px);
  left: 0;
}

.edit-stat-box > button.side {
  display: none;
}

.edit-stat-box:focus-within > button {
  display: block;
}

.error {
  box-shadow: inset 0 0 1em red;
}

.edit-name-box {
  text-align: left;
  min-width: 100px;
  position: sticky;
}

.edit-name-box:hover input, .edit-name-box:focus-within input {
  filter: brightness(125%);
}

.edit-name-box textarea {
  box-sizing: border-box;
  text-align: center;
  resize: none;
  background-color: #0000;
  border: none;
  margin: 0;
  display: block;
  top: 0;
  left: 0;
}

.xp-hint {
  opacity: .5;
  font-size: .75em;
  position: absolute;
  bottom: 0;
  right: 0;
}

.tagList {
  color: var(--theme);
  background-color: #0000;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  inset: 0;
  overflow: hidden;
}

.tagList:hover {
  background-color: var(--theme);
  color: var(--text);
}

.listCell {
  padding: 0;
  position: relative;
}

.tagList span {
  display: inline-block;
}

label.checked {
  background-color: var(--active);
}

.scroll table {
  margin-top: 0;
}

.inlineList {
  margin: .5em;
}

.modal .pane label, .modal .pane button.inlineList {
  width: -moz-fit-content;
  width: fit-content;
  display: block;
}

.modal .pane label:has(input:checked), .modal .pane label:has(#item-selector-filter-name-box:not([value=""])) {
  background-color: var(--active);
}

.modal .pane .inline label {
  display: inline-block;
}

.pane ol {
  color: var(--text);
}

.pane ol li {
  margin-left: 2em;
  margin-right: 2em;
  list-style-type: decimal;
  list-style-position: outside;
}

.pane ol li input {
  width: 80%;
}

.muted {
  opacity: .3;
}

ul.equipment ul {
  margin-left: 1em;
  list-style-type: none;
}

.equipment li span:after {
  content: ", ";
}

.equipment li span:last-child:after {
  content: "";
}

.EntityEditTab .panel textarea {
  width: 100%;
}

@media screen and (min-width: 880px) {
  .EntityEditTab {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    justify-content: stretch;
    justify-items: stretch;
    display: grid;
  }

  .EntityEditTab .panel {
    background-color: var(--freeze-dark);
    margin: 1px;
    padding: .5em;
    overflow: auto;
  }

  .EntityEditTab .panel table {
    width: 100%;
  }

  .namepanel {
    grid-row: span 1;
  }

  .aspectspanel, .primariespanel {
    grid-column: span 2;
  }

  .tacticalspanel {
    grid-area: span 2 / span 2;
  }

  .thresholdspanel, .poolspanel {
    grid-column: span 2;
  }

  .skillspanel {
    grid-area: span 3 / span 3;
  }

  .equipmentpanel, .infopanel {
    grid-column: span 2;
  }
}

.keycell > div {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.keycell > div > * {
  flex: none;
}

.keycell button.icon {
  border-radius: unset;
  background-color: #0000;
  padding: 0;
}

.keycell button.icon .icon {
  display: block;
}

.keycell > div > :first-child, .keycell > div > :nth-child(2) {
  flex-basis: 2em;
}

.keycell > div > :nth-child(3) {
  flex: auto;
}

.keycell > div > :nth-child(4) {
  text-align: right;
  flex: 6ch;
  position: relative;
}

.keycell > div > :nth-child(4):after {
  content: "xp";
  vertical-align: top;
  opacity: .5;
  font-size: .7em;
}

.keycell a:hover, .keycell button:hover {
  text-shadow: 0 0 1em var(--theme);
}

.pool-boxes {
  column-span: all;
}

.stressbox {
  width: 2em;
  height: 2em;
}

.heal {
  background-color: green;
}

.harm {
  background-color: red;
}

.boxcell {
  padding: 0;
}

.boxcell label {
  flex-direction: row;
  width: 100%;
  margin: 2px;
  display: flex;
}

.boxcell label span, .boxcell label input, .boxcell label button, .stresstrack label span, .stresstrack label input, .stresstrack label button {
  vertical-align: middle;
  flex-basis: 3em;
}

.boxcell label input[type="number"], .stresstrack label input[type="number"] {
  text-align: center;
  width: 3em;
  font-size: 2em;
}

.boxcell .clearboxes {
  float: right;
}

.stresstrack .track {
  flex-direction: row;
  align-items: stretch;
  display: flex;
}

.stresstrack .poolStatus {
  padding: 0 5px;
}

.portrait {
  cursor: pointer;
  max-width: 100%;
}

.portrait.icon {
  max-height: 2em;
}

.printSettings {
  max-width: 8.5in;
  margin-bottom: 1em;
}

.printTypeSelector, .printTypeSelector td {
  border-collapse: collapse;
  border: thin solid;
  padding: 0;
}

.printTypeSelector label {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.printTypeSelector label input[type="radio"] {
  background-color: var(--theme);
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 1em;
  margin: auto;
  padding: 0;
  display: block;
}

.printTypeSelector label input[type="radio"]:before {
  content: "☐";
  text-align: center;
}

.printTypeSelector label input:checked {
  background-color: var(--active);
}

.printTypeSelector label input[type="radio"]:checked:before {
  content: "🗹";
}

.printTypeSelector td {
  padding: 0;
}

@media print {
  .SocketDocEdit h1:first-of-type {
    display: none;
  }

  body, html, #content-output, .container .SocketDocEdit, .docEditor, .container, .container.DocPage, .docTab, #root > div, .SocketDocEdit {
    clear: both;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    overflow: visible;
  }

  #content-output {
    max-width: auto;
  }

  html, body, .container, .footer {
    background-color: #0000;
  }

  ::-webkit-scrollbar-track {
    background-color: #0000;
  }

  ::-webkit-scrollbar-corner {
    background-color: #0000;
  }
}

#content-output {
  max-width: 8.5in;
}

#content-output.Entity.Cards {
  columns: 3;
  column-gap: 0;
  display: block;
}

.card {
  break-inside: avoid;
  page-break-inside: avoid;
  border: 2pt solid;
  margin: 2pt 1pt;
  padding: .5em;
  font-size: 8pt;
  display: block;
}

.card:first-of-type {
  margin-top: 0;
}

.card hr {
  border: none;
  border-top: thin dotted;
  margin: .5em 0;
}

#content-output h1, #content-output h2, #content-output h3, #content-output h4, #content-output h5, #content-output h6 {
  font-weight: bolder;
}

#content-output li {
  list-style-type: disc;
  list-style-position: inside;
}

#content-output ul ul {
  padding-left: 1em;
}

#content-output code {
  word-wrap: break-word;
  font-style: italic;
}

pre#content-output {
  word-wrap: break-word;
  white-space: pre-wrap;
}

#content-output h1, #content-output h2, #content-output h3, #content-output h4, #content-output h5, #content-output h6 {
  break-after: avoid;
  page-break-after: avoid;
}

#content-output.Stat.Blocks {
  columns: 2;
  column-rule: 2pt solid;
  column-gap: 2em;
  font-size: 10pt;
}

#content-output.Stat.Blocks h2 {
  border-top: 2pt solid;
  margin-top: 1em;
}

#content-output.Item.Table {
  -webkit-print-color-adjust: exact;
  font-size: 8pt;
}

#content-output.Stat.Blocks.JSON, #content-output.Entity.Cards.JSON {
  columns: 1;
}

#content-output .tag {
  color: inherit;
  text-shadow: none;
  background-color: #0000;
}

div.docSave {
  z-index: 3000;
  position: fixed;
  top: .5em;
  right: 1.5em;
}

.api ul {
  margin-left: 1em;
  list-style-type: disc;
}

.api p {
  margin-top: 1em;
  margin-bottom: 1em;
}

.api blockquote {
  margin-left: 1em;
  font-style: italic;
}

.api code.block {
  border: thin solid;
  margin: 1em;
  padding: 1em;
  display: block;
}

div.eventList li {
  margin-left: 1em;
}

div.eventList li.event {
  border: thin solid;
}

form.reset {
  max-width: 300px;
}

form.reset > * {
  width: 100%;
  margin: 3px 0;
  display: block;
}

form.reset input[type="email"] {
  background-color: #0000;
  border: none;
}

/*# sourceMappingURL=index.aa080919.css.map */
