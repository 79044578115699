.edit-name-box {
    position: sticky;
    min-width: 100px;
    text-align: left;
}
.edit-name-box:hover input,
.edit-name-box:focus-within input {
    filter: brightness(125%);
}
.edit-name-box textarea {
    /* height: 100%;
    width: 100%;
    position: absolute; */
    /* min-height: 1.5em; */
    display: block;
    box-sizing: border-box;
    margin: 0px;
    top: 0px;
    left: 0px;
    background-color: transparent;
    border: none;
    text-align: center;
    resize: none;
}
.xp-hint {
    position: absolute;
    bottom: 0em;
    right: 0em;
    font-size: .75em;
    opacity: .5;
}
