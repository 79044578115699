.patronList > div {
    border-bottom: solid thin;
    margin-bottom: 1em;
    padding-bottom: 1em;
}
/* .galaxy li, .eldritch li {
    font-size: 1.8em;
    font-weight: bolder;
    text-transform: uppercase;
    text-shadow: 0 0 5px rgb(255, 0, 255);
} */
.cyclone li {
    font-size: 1.5em;
    font-weight: bolder;
    text-transform: uppercase;
}
.cyclone ul {
    columns: 2;
}
.helix li {
    font-size: 1.2em;
}
.helix ul {
    columns: 2;
}
.spiral li {
    font-size: 1em;
}
.spiral ul {
    columns: 3;
}