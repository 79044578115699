.encounter {
    background-color: var(--freeze-dark);
    margin: 2pt;
    padding: 1em;
}
.encounter td > input {
    width: 100%;
}
.encounter table {
    /* max-width: 600px; */
    width: auto;
}
.encounter span.hidden {
    float: right;
    /* visibility: visible; */
}
.encounter .graph {
    background-color: var(--dark);
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    /* border: 2pt solid black; */
}
.encounter .graph .line {
    position: absolute;
    top: 0px;
    bottom: 0px;
    text-align: right;
    border-right: 3px solid white;
    transition: all .3s ease;
}
.encounter .graph div {
    /* height: 1em; */
    /* margin: 1pt; */
    padding: .5em;
    color: white;
    box-sizing: border-box;
    transition: all .3s ease;
}
.encounter .graph .good {
    background-color: rgb(72, 161, 72);

}
.encounter .graph .evil {
    margin-top: 3pt;
    background-color: rgb(175, 72, 72);
}
.encounter .combatant {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 1em 0;
}
.encounter .statblocks {
    background-color: var(--freeze-dark);
}
.encounter .statblocks > div {
    font-size: 85%;
}
.encounter .floatblock {
    clear: both;
}
.encounter .notes {
    width: 100%;
}
.encounter .statblocks ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 1em;
}
.mobile.encounter li > label{
    display: block;
}
.mobile.encounter label input {
    color: black;
}
.mobile.encounter ul li label input[type="number"] {
    width: 50%;
}
.mobile.encounter .enli {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 1em 0;
}
@media screen and (min-width: 500px) {
    .encounter .statblocks {
        float: right;
        max-width: 50%;
    }
    .encounter .statblocks > div {
        padding-left: 1em;
    }
}
@media screen and (max-width: 500px) {
    .encounter h3 span {
        display: block;
    }
    .encounter h3 span.hidden {
        float: initial;
    }
    .encounter .graph .line {
        color: transparent;
    }
    .encounter .stresstrack span {
        display: block;
        width: 100%;
    }
    .container:not(.DocPage) {
        /* overflow-y: unset; */
    }
    h1 input {
        display: block;
        max-width: 100%;
    }
}