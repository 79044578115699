form.reset {
    max-width: 300px;
}

form.reset > * {
    display: block;
    width: 100%;
    margin: 3px 0px;
}
form.reset input[type='email'] {
    background-color: transparent;
    border: none;
}