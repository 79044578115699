.stressbox {
    height: 2em;
    width: 2em;
}
.heal {
    background-color: green;
}
.harm {
    background-color: red;
}
.boxcell {
    padding: 0;
}
.boxcell label {
    margin: 2px;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.boxcell label span,
.boxcell label input,
.boxcell label button,
.stresstrack label span,
.stresstrack label input,
.stresstrack label button {
    /* outline: 1px dotted cyan; */
    vertical-align: middle;
    flex-basis: 3em;
}
.boxcell label input[type='number'],
.stresstrack label input[type='number'] {
    width: 3em;
    text-align: center;
    font-size: 2em;
}
.boxcell .clearboxes {
    float: right;
}
.stresstrack .track {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
.stresstrack .poolStatus {
    padding: 0px 5px;
}