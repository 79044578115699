.spinner {
    animation: spin 1s linear infinite;
    height: 50px;
    width: 50px;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
        opacity: 1;
    }
    50% {
        opacity: 1;
        /* opacity: .5; */
    }
    100% {
        transform: rotate(360deg);
        opacity: 1;
    }
}
.spinbox.fullpage {
    width: 100%;
    /* height: 100%; */
    /* background-color: red; */
    padding-left: 50%;
    padding-top: 25%;
}
.spinner.small {
    height: 1em;
    width: 1em;
}