.edit-stat-box {
    position: relative;
    /* z-index: -100; */
}
.edit-stat-box:hover input,
.edit-stat-box:focus-within input {
    filter: brightness(125%);
}
.edit-stat-box input,
.edit-stat-box select {
    height: 100%;
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: transparent;
    border: none;
    text-align: center;
}
.edit-stat-box button.whole {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}
.edit-stat-box:hover > button.whole {
    display: block;
}
.edit-stat-box > button.bottom {
    width: 100%;
    height: auto;
    margin-top: 5px;
    position: absolute;
    top: calc(100% - 5px);
    left: 0px;
    z-index: 500;
}
.edit-stat-box > button.side {
    display: none;
}
.edit-stat-box:focus-within > button {
    display: block
}
.error {
    box-shadow: 0 0 1em red inset;
}