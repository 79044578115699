.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* table, td, th, td {
  border: thin solid black;
  border-collapse: collapse;
} */
.tag:before {
  content: "#"
}
.tag,
.UserInline {
  border-radius: .3em;
  padding: 0 3px;
  background-color: rgb(228, 228, 228);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.287);
  color: black;
  margin: 1px;
}
.tag,
.UserInline a {
  text-decoration: none;
  /* color: black; */
}
tr.unlisted,
tr.trashed,
li.unlisted,
li.trashed {
  opacity: 0.5;
}
tr.unlisted:hover,
tr.trashed:hover {
  opacity: 1;
}
.devmenu {
  background:rgba(90, 0, 0, 0.287);
  padding: 1em;
  border: thin solid orangered;
}
.devmenu ul {
  display: none;
}
.devmenu:hover ul {
  display: block;
}
#app > div {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
/* #root > div > div {
  border: thin solid;
} */
.HeadBranding img {
  max-width: 100%;
}
.navpane {
  order: -1;
  flex-grow: 1;
  flex-basis: 200px;
  max-width: 200px;
  min-width: 200px;
  padding: 1em;
  overflow-y: auto;
}
.navpane ul {
  list-style-type: none;
}
.navpane ul li ul {
  margin-left: 1em;
}
.container {
  order: 2;
  flex-grow: 4;
  /* display: flex;
  flex-direction: column; */
}
.container:not(.DocPage) {
  overflow-y: scroll;
}
.container.DocPage {
  /* max-height: 100vh;
  height: 100vh; */
  max-width: calc(100vw - 200px);
  width: calc(100vw - 200px);

}
.container .SocketDocEdit {
  height: 100%;
  width: 100%;
}
.modal .pane {
  height: 60vh;
  width: 50vw;
  display: block;
  background-color: white;
  position: fixed;
  box-shadow: 0 0 1em black;
  top: 20vh;
  left: 25vw;
  padding: 1em;
  z-index: 12000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.modal .pane ul,
.modal .pane ol {
  overflow-y: auto;
}
.modal {
  position: fixed;
  content: " ";
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
}
.modal .pane {
  background-color: var(--bg);
}
.modal .pane .spacer {
  /* content: ' '; */
  flex-grow: 10;
}
.modal .bg-button {
  background-color: rgba(0, 0, 0, 0.637);
  position: fixed;
  content: " ";
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2000;
}
.modal .scroll {
  overflow: scroll;
  padding-bottom: 1em;
}
button.delete,
.button.delete {
  background-color: red;
}
.button {
  display: inline-block;
  padding: .1em .3em;
  text-align: center;
  border: thin solid;
  border-radius: .5em;
}
.button:hover {
  background: rgba(0, 0, 0, 0.144);
  box-shadow: 0 0 3px inset;
}
table thead {
  font-weight: bolder;
}
.input-cell {
  padding: 0;
  position: relative;
}
.input-cell input,
.input-cell select,
.input-cell textarea {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
select,
input:not([type]),
input[type='text'],
input[type='password'] {
  padding: .1em .3em;
}
/* select {
    background-image:
      linear-gradient(45deg, transparent 50%, var(--theme) 50%),
      linear-gradient(135deg, var(--theme) 50%, transparent 50%),
      linear-gradient(to right, var(--theme), var(--theme));
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
}
select:focus {
  background-image:
    linear-gradient(45deg, var(--active) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, var(--active) 50%),
    linear-gradient(to right, var(--active), var(--active));
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  outline-color: var(--active);
} */
label {
  user-select: none;
}
form > * {
  display: block;
}
form input[type='submit'] {
  display: block;
  width: 100%;
}
form label {
  display: flex;
  flex-direction: row;
}
form label input {
  flex-grow: 1;
}
.pill {
  padding: 0 .3em;
  border-radius: 1em;
  display: inline-block;
  box-shadow: 0 .2em .2em rgba(0, 0, 0, 0.342);
  margin: .1em;
  position: relative;
  padding-right: 1em;
  font-size: 12pt;
}
.listHead {
  min-width: 10ch;
}
table thead tr td,
table thead tr th {
  text-align: center;
}
::placeholder {
  color: var(--text);
  opacity: .5;
}
*[title]:hover {
  cursor: help;
}
.mobile {
  display: none;
}
.desktop {
  display: block;
}
/* [title] {
  position: relative;
}
[title]::after {
  content: 'ℹ';
  position: absolute;
  top: -.5em;
  right: 0em;
  font-size: .5em;
  border: thin solid;
  border-radius: .5em;
  background-color: var(--accent);
  padding: 0 .3em;
  opacity: .5;
} */
@media screen and (max-width: 641px) {
  body {
    width: 100vw;
  }
  #root > div {
    /* display: block; */
    flex-direction: column;
    height: auto;
  }
  div.container {
    margin-bottom: 5em;
  }
  div.container, div.container.DocPage {
    width: 95vw;
    max-width: 95vw;
    margin-left: auto;
    margin-right: auto;
  }
  div.docEditor {
    max-height: none;
    height: auto;
    overflow: auto;
    width: 100%;
  }
  div.docTab {
    max-height: none;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
  }
  div.panel {
    /* max-width: 100%; */
    width: 100%;
    overflow: auto;
  }
  div.EntityEditTab {
    display: block;
  }
  div.container div.SocketDocEdit {
    height: auto;
    width: auto;
  }
  div.navpane {
    max-height: none;
    max-width: none;
    height: auto;
    width: 100%;
    overflow-y: initial;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .navpane {
    flex-basis: 0px;
  }
  #nav {
    display: none;
  }
  #nav.show {
    display: block;
  }
  body div.docSave {
    bottom: 0px;
    top: auto;
    right: 0em;
    left: 0em;
    height: 1.7em;
    width: 100%;
  }
  body div.docSave button {
    width: 100%;
  }
  body .modal .pane {
    width: 90vw;
    height: 90vh;
    top: 5vh;
    left: 5vw;
  }
  /* body .modal .pane label {
    display: inline;
  } */
  body .modal .pane label input {
    width: 15ch;
  }
}
@media screen {
  .lightbg {
    display: none;
  }
}
@media print {
  .darkbg,
  .namepanel button,
  .aspectpanel button,
  .aspectpanel td:nth-child(3),
  .statspanel .muted,
  .poolspanel button,
  .equipmentpanel .modify,
  html body .EntityEditTab .panel.noprint,
  .equipmentpanel button
  {
    display: none;
  }
  .portraitpanel button {
    visibility: hidden;
  }
  .portraitpanel img {
    visibility: visible;
  }
  html body .panel table {
    width: 100%;
  }
  html body label,
  html body button {
    background-color: transparent;
    color: black;
  }
  html body div.panel.equipmentpanel {
    flex-grow: 2;
  }
  html {
    font-size: 10pt;
    overflow: visible;
    height: auto;
  }
  html body {
    color: black;
    text-shadow: none;
    background-color: transparent;
    overflow: auto;
    height: auto;
  }
  body h1,
  body h2,
  body h3,
  body h4,
  body h5,
  body h6 {
    color: black;
    text-shadow: none;
  }
  body tbody tr:nth-child(even),
  body tbody tr:nth-child(even) td {
    background-color: rgba(0, 0, 0, 0.144);
    /* box-shadow: 0 1rem 0.75rem -0.75rem rgba(0,0,0,0.07); */
  }
  body tbody tr:nth-child(odd),
  body tbody tr:nth-child(odd) td {
    background-color: transparent;
    /* box-shadow: 0 1rem 0.75rem -0.75rem rgba(0,0,0,0.07); */
  }
  body thead tr td,
  body thead tr th {
    background-color: rgba(0, 0, 0, 0.144);
    color: black;
    font-weight: 400;
    text-align: left;
  }
  body tbody tr:nth-child(even) td.freeze-col {
    background-color: rgba(0, 0, 0, 0.287);
  }
  body tbody tr:nth-child(odd) td.freeze-col {
    background-color: transparent;
  }
  body .entity-table thead td {
    background-color: rgba(0, 0, 0, 0.287);
  }
  body .EntityEditTab .panel {
    border: 1px solid black;
    overflow: visible;
    background-color: transparent;
  }
  html[lang] {
    --root-fs: 10px
  }
  html[lang] #app > div {
    width: unset;
    height: unset;
    display: block;
    overflow: unset;
  }
}