.footer {
    /* border-top: 1px solid var(--theme); */
    /* background-color: var(--bg); */
    background-color: var(--darker);
    padding: .5em;
    padding-bottom: 2em;
    margin-top: 2em;
    bottom: 0px;
    position: sticky;
    top: 100vh;
}
/* .footer:after {
    background-color: rgba(0,0,0,.2);
    background-color: red;
    content: '';
    height: 100vh;
    width: 100%;
    display: block;
    float: left;

    bottom: 0px;
} */
.foot-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.small {
    font-size: .8em;
}
.subtle {
    opacity: .75;
}
.foot-logo {
    height: 50px;
    width: 50px;
}
/* .foot-logo:active {
    animation: spin .5s linear infinite;
} */

@keyframes spin {
    0% {
        transform: rotate(0deg);
        opacity: 1;
    }
    50% {
        opacity: 1;
        /* opacity: .5; */
    }
    100% {
        transform: rotate(360deg);
        opacity: 1;
    }
}

@keyframes unspin {
    100% {
        transform: rotate(720deg);
    }
}