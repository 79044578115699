.docEditor .docTab .tab {
    display: none;
}
.docEditor .docTab .tab.active {
    display: block;
}
.docEditor .docNav {
    display: flex;
    height: 2rem;
}
.docEditor .docNav .button {
    flex-grow: 1;
}
.docEditor .docNav .button.active {
    background-color: var(--active);
    color: white;
}
.docEditor {
    max-height: 100%;
    height: 100%;
    /* overflow: scroll; */
}
.docTab {
    max-height: 100%;
    height: calc(100% - 5.4rem);
    overflow: scroll;
}