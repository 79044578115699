.formulaEditBox {
    /* border: thin solid; */
    /* box-shadow: .2em .2em .2em rgba(0, 0, 0, 0.192) inset; */
    padding: .2em;
}
.opArray > .expressionNode:not(:last-child):after{
    content: ', ';
}
.expressionNode {
    color: var(--bg);
    box-shadow: .2em .2em .2em rgba(0, 0, 0, 0.192);
    /* border: thin solid; */
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.061);
    padding: 0 .2em;
    position: relative;
    margin: .2em;
    /* padding-right: 1em; */
}
.expressionNode[data-op="none"] select {
    color: rgb(138, 0, 0);
}
.expressionNode[data-op="none"] select option,
.expressionNode[data-op="none"] select optgroup {
    color: inherit;
}
.expressionNode select optgroup {
    color: var(--bg);
    background-color: var(--text);
}
.expressionNode input[type="number"] {
    width: 2em;
}
.expressionNode input,
.expressionNode select {
    background-color: inherit;
    color: var(--bg);
    border: none;
}
.expressionNode select option {
    /* background-color: inherit; */
    color: var(--text);
}
.expressionNode select option[data-op] {
    color: var(--bg);
}
.expressionNode select {
    /* appearance: none; */
    /* overflow: hidden; */
    padding: .2em;
}
.expressionNode select:hover {
    /* appearance: initial; */
    cursor: text;
}


/* static colors ------------------------*/
[data-op='wrap'      ] {     background-color: hsla(200, 50%, 50%, 1)}
[data-op='unwrap'    ] {     background-color: hsla(20, 50%, 50%, 1)}
[data-op='push'      ] {     background-color: hsla(120, 50%, 50%, 1)}
[data-op='remove'    ] {     background-color: hsla(300, 50%, 50%, 1)}

/* generated ---------------------------*/
[data-op='none'      ] {     background-color: hsla(0, 75%, 80%, 1)}
[data-op='average'   ] {     background-color: hsla(24, 75%, 80%, 1)}
[data-op='max'       ] {     background-color: hsla(48, 75%, 80%, 1)}
[data-op='min'       ] {     background-color: hsla(72, 75%, 80%, 1)}
[data-op='sum'       ] {     background-color: hsla(96, 75%, 80%, 1)}
[data-op='add'       ] {     background-color: hsla(120, 75%, 80%, 1)}
[data-op='subtract'  ] {     background-color: hsla(144, 75%, 80%, 1)}
[data-op='multiply'  ] {     background-color: hsla(168, 75%, 80%, 1)}
[data-op='divide'    ] {     background-color: hsla(192, 75%, 80%, 1)}
[data-op='integer'   ] {     background-color: hsla(216, 75%, 80%, 1)}
[data-op='fraction'  ] {     background-color: hsla(240, 75%, 80%, 1)}
[data-op='decimal'   ] {     background-color: hsla(264, 75%, 80%, 1)}
[data-op='best'      ] {     background-color: hsla(288, 75%, 80%, 1)}
[data-op='statref'   ] {     background-color: hsla(312, 75%, 80%, 1)}
[data-op='flatten'   ] {     background-color: hsla(336, 75%, 80%, 1)}

