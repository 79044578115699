.pane ol {
    color: var(--text);
}
.pane ol li {
    list-style-type: decimal;
    margin-left: 2em;
    margin-right: 2em;
    list-style-position: outside;
}
.pane ol li input {
    width: 80%;
}